import { lazy } from "react";
import { Navigate, Route, Link } from "react-router-dom";
import ProtectedRoute from './ProtectedRoute/index';

const Header = lazy(() => import("../../components/Header"));
const Footer = lazy(() => import("../../components/Footer"));
const Login = lazy(() => import("../../screen/Login"));
// const NotFound = lazy(() => import('../../screen/NotFound'));
const Campaign = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/Campaign")
);
const CampaignCreate = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/CampaingCreate")
);
const Management = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/Management")
);
const ManagementUser = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/Management/ManagementUser/managementUser")
);
const MyAccount = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/MyAccount/myAccount")
);
const BlackListScreen = lazy(
  () => import("../../screen/Management/blacklist/blacklistScreen")
);
const UserInterface = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/UserInterface")
);
const Suporte = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/Suporte")
);
const CustomerResponse = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/CustomerResponse")
);
const Logs = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/Logs")
);
const ForgotPassword = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/ForgotPassword")
);
const NewPassword = lazy(
  () => import(/* webpackPrefetch: true */ "../../screen/NewPassword")
);
const ResponsesReport = lazy(() => import("../../screen/Management/Responses/responsesReport.jsx"));
const Consolidated = lazy(() => import("../../screen/Management/consolidated/consolidated"));
const DetailedScreen = lazy(() => import("../../screen/Management/detailed/detailedScreen"));
const SendReport = lazy(() => import("../../screen/Management/sendReport/sendReport"));
const ZendeskAuth = lazy(() => import("../../screen/ZendeskAuth"));

export const ROUTE_PATHS = {
  LOGIN: "/",
  FORGOT_PASSWORD: "/forgot-password",
  NEW_PASSWORD: "/new-password",
  NOT_FOUND: "/404",
  CAMPAIGN: "/campaign",
  CAMPAIGN_CREATE: "/campaign-create",
  MANAGEMENT: "/management",
  MY_ACCOUNT: "/my-account",
  MANAGEMENT_USER: "/managementUser",
  BLACK_LIST: "/black-list",
  USER_INTERFACE: "/user-interface",
  LOGS: "/logs",
  CUSTOMER_RESPONSE: "/customer-response",
  SUPORTE: "/Suporte",
  RESPONSE: "/responseReport",
  CONSOLIDATE: "/consolidated",
  DETAILED: "/detailedScreen",
  SEND: "/sendReport",
  ZENDESK_SSO: "/services/login"
};



export const ROUTE_PATHS2 = {
  CAMPAIGN_CREATE: {
    text: 'Campanhas',
    path: "/campaign-create",
  },

  CONSOLIDATE: {
    text: 'Gerenciamento de SMS',
    path: "/consolidated",

  },

  /*   DETAILED: {
      text: 'Relatório Detalhado',
      path: "/detailedScreen",
  
    },
  
    SEND: {
      text: 'Resumo de Envios',
      path: "/sendReport",
  
    },
  
    RESPONSE: {
      text: 'Relatório de Respostas',
      path: "/responseReport",
  
    },
  
    BLACK_LIST: {
      text: "Black List",
      path: "/black-list"
    }, */

  MANAGEMENT_USER: {
    text: 'Gerenciamento de Usuários',
    path: "/managementUser",
  },

  MY_ACCOUNT: {
    text: 'Minha Conta',
    path: "/my-account",
  },

  SUPORTE: {
    text: 'Suporte',
    path: "https://oicorreios.zendesk.com/hc/pt-br",
  },
};

export const ROUTE_PATHS3 = {
  CAMPAIGN_CREATE: {
    text: 'Campanhas',
    path: "/campaign-create",
  },

  CONSOLIDATE: {
    text: 'Gerenciamento de SMS',
    path: "/consolidated",

  },

  /*  DETAILED: {
     text: 'Relatório Detalhado',
     path: "/detailedScreen",
 
   },
 
   SEND: {
     text: 'Resumo de Envios',
     path: "/sendReport",
 
   },
 
   RESPONSE: {
     text: 'Relatório de Respostas',
     path: "/responseReport",
 
   },
 
   BLACK_LIST: {
     text: "Black List",
     path: "/black-list"
   },
  */
  MY_ACCOUNT: {
    text: 'Minha Conta',
    path: "/my-account",
  },

  // SUPORTE: {
  //   text: 'Suporte',
  //   path: "/Suporte",
  // },

};

const routes = [
  {
    path: ROUTE_PATHS.ZENDESK_SSO,
    element: (
      <ProtectedRoute>
        <ZendeskAuth />
      </ProtectedRoute>
    )
  },
  {
    path: ROUTE_PATHS.LOGIN,
    element: (
      <>
        <Login />
      </>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.FORGOT_PASSWORD,
    element: (
      <>
        <ForgotPassword />
      </>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.NEW_PASSWORD,
    element: (
      <>
        <NewPassword />
      </>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.NOT_FOUND,
    element: (
      <>
        <h1>Error 404</h1>
      </>
    ),
  },
  { path: "*", element: <Navigate to={ROUTE_PATHS.NOT_FOUND} /> },
  {
    path: ROUTE_PATHS.CAMPAIGN,
    element: (
      <ProtectedRoute>
        <Header>
          <Campaign />
        </Header>
      </ProtectedRoute>
    ),
    children: [],

  },
  {
    path: ROUTE_PATHS.CAMPAIGN_CREATE,
    element: (
      <ProtectedRoute>
        <Header>
          <CampaignCreate />
        </Header>
      </ProtectedRoute>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.MANAGEMENT,
    element: (
      <ProtectedRoute>
        <Header>
          <Management />
        </Header>
      </ProtectedRoute>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.MY_ACCOUNT,
    element: (
      <ProtectedRoute>
        <Header>
          <MyAccount />
        </Header>
      </ProtectedRoute>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.MANAGEMENT_USER,
    element: (
      <ProtectedRoute>
        <Header>
          <ManagementUser />
        </Header>
      </ProtectedRoute>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.BLACK_LIST,
    element: (
      <ProtectedRoute>

        <Header>
          <BlackListScreen />
        </Header>

      </ProtectedRoute>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.USER_INTERFACE,
    element: (
      <ProtectedRoute>
        <Header>
          <UserInterface />
        </Header>
      </ProtectedRoute>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.CUSTOMER_RESPONSE,
    element: (
      <ProtectedRoute>
        <Header>
          <CustomerResponse />
        </Header>
      </ProtectedRoute>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.LOGS,
    element: (
      <ProtectedRoute>
        <Header>
          <Logs />
        </Header>
      </ProtectedRoute>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.SUPORTE,
    element: (
      <ProtectedRoute>
        <Header>
          <Suporte />
        </Header>
      </ProtectedRoute>
    ),
    children: [],
  },
  {
    path: ROUTE_PATHS.RESPONSE,
    element: (
      <ProtectedRoute>
        <Header >
          <ResponsesReport />
        </Header>
      </ProtectedRoute>
    ),
    children: []

  },
  {
    path: ROUTE_PATHS.CONSOLIDATE,
    element: (
      <ProtectedRoute>
        <Header>
          <Consolidated />
        </Header>
      </ProtectedRoute>
    ),
    children: []
  },
  {
    path: ROUTE_PATHS.DETAILED,
    element: (
      <ProtectedRoute>
        <Header >
          <DetailedScreen />
        </Header>
      </ProtectedRoute>
    ),
    childrem: []
  },
  {
    path: ROUTE_PATHS.SEND,
    element: (
      <ProtectedRoute>
        <Header >
          <SendReport />
        </Header>
      </ProtectedRoute>
    ),
    childrem: []
  }
];

export default routes;
