import { Navigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../index';
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from '../../../contexts/globalContext';
import { brokerApi } from "../../../service/http-out/brokerApi";

// @ts-ignore
export default function ProtectedRoute({
    children,
}: {
    children: JSX.Element;
}) {
    //@ts-ignore
    const { isAuthenticated } = useContext(GlobalContext);

    useEffect(() => {
        const userId = localStorage.getItem("id");
        if(userId) {
            brokerApi.getUser().then((res) => {
                const [currentUser] = res.data.filter(
                  (user: any) => user.id === userId
                );
                localStorage.setItem("currentUser", JSON.stringify({ name: currentUser.name, email: currentUser.email }));
              });
        }
    });

    const checkTokenValidity = () => {
        const token = localStorage.getItem("token");
        if(token) {
            const base64Url = token.split(".")[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            const tokenExpirationDate = JSON.parse(atob(base64)).exp * 1000;
            const now = Math.round(new Date().getTime() / 1000)
            return tokenExpirationDate > now
        }   
    }

    // if (!isAuthenticated) {
    //     // if (accessToken !== null) {
    //     return children;
    // }
    if (!isAuthenticated && !checkTokenValidity()) {
        return <Navigate to={ROUTE_PATHS.LOGIN} />;
    }
    return children;
}
