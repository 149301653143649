import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
//import { Auth0Provider } from '@auth0/auth0-react';

const AppProvider = (
    // <OauthProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    // </OauthProvider>
);

ReactDOM.render(AppProvider, document.getElementById('root'));


// function OauthProvider({ children }: { children: JSX.Element }) {
//     const token = localStorage.getItem('token');
//     // if (!initialState.oauth) {
//     if (!token) {
//       return children;
//     }
//     // const { domain, clientId, scopes, audience } = initialState.oauth;
//     // if (!clientId) {
//     //   alert(
//     //     'Need to define a clientId. Please check the file store/initalStateSlice.js',
//     //   );
//     // }
  
//     return (
//       <>
//       {/*<Auth0Provider*/}
//       {/*   domain={domain}*/}
//       {/*   clientId={clientId}*/}
//       {/*   redirectUri={window.location.origin}*/}
//       {/*   scopes={scopes.join(' ')}*/}
//       {/*   audience={audience}*/}
//       {/*   cacheLocation='localstorage'*/}
//       {/* >*/}
//         {children}
//       {/*</Auth0Provider>*/}
//       </>
//     );
//   }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
