import React from "react";
import routes from "./config/routes";
import { useRoutes } from "react-router-dom";
import LazyLoadRoute from "./config/routes/LazyLoadRoute";
import ProtectedRoute from "./config/routes/ProtectedRoute";
import DefaultView from "./components/DefaultView";
import { AppDiv } from "./styles/global";
import GlobalContextProvider from './contexts/globalContext';


function App() {
  const routing = useRoutes(routes);

  return (
    // <ThemeProvider theme={theme}>
    //     <CssBaseline />
    //     <Grid container direction='column' className={classes.app}>
    <GlobalContextProvider>
      <AppDiv>
        <LazyLoadRoute>
            <DefaultView>{routing}</DefaultView>
        </LazyLoadRoute>
      </AppDiv>
    </GlobalContextProvider>

    // </Grid>
    // </ThemeProvider>
  );
}

export default App;
