import{ useState, createContext } from "react";

export const GlobalContext = createContext({});

//@ts-ignore
function GlobalContextProvider({ children }){
    const [ isAuthenticated, setIsAuthenticated ] = useState(false);
    const [ currentUser, setCurrentUser ] = useState({});

    return(
        <GlobalContext.Provider value ={{ 
            isAuthenticated, setIsAuthenticated,
            currentUser, setCurrentUser
            }}>
            {children}
        </GlobalContext.Provider>
    )
}
export default GlobalContextProvider;